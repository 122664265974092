const ADMINISTRATE = {
    ADMINISTRATE: '/administrate',
    ADMINISTRATE_TASKS: '/administrate/tasks',
    MESSAGE_HISTORY: '/administrate/messageHistory',
    MANAGE_TASK_TEMPLATE: '/administrate/tasks/manage_task_template/create',
    MANAGE_TASK_TEMPLATE_SINGLE: (id = ':id') => `/administrate/tasks/manage_task_template/${id}` as const,
    KITS_SINGLE: (id = ':option') => `/administrate/kits/${id}` as const,
    ADMINISTRATE_LOCATIONS: '/administrate/locations',
    ADMINISTRATE_FEATURE_SETTINGS: '/administrate/feature-settings',
    ADMINISTRATE_PERSONNEL: '/administrate/personnel',
    ADMINISTRATE_ACTIVE_PERSONNEL: '/administrate/personnel/active',
    ADMINISTRATE_ARCHIVED_PERSONNEL: '/administrate/personnel/archived',
    ADMINISTRATE_DATA_COLLECTION: '/administrate/data-collection',
    ADMINISTRATE_DATA_COLLECTION_DAILY: '/administrate/data-collection/daily',
    ADMINISTRATE_DATA_COLLECTION_WEEKLY: '/administrate/data-collection/weekly',
    ADMINISTRATE_DATA_COLLECTION_MONTHLY: '/administrate/data-collection/monthly',
    ADMINISTRATE_DATA_COLLECTION_OTHER: '/administrate/data-collection/other',
    ADMINISTRATE_DATA_COLLECTION_CONFIGURATION: '/administrate/data-collection/configuration',
    ADMINISTRATE_PROFILE_PERSONNEL: (id = ':id') => `/administrate/personnel/${id}`,
    ADMINISTRATE_LOGGED_IN_USERS: '/administrate/loggedInUsers',
    ADMINISTRATE_DEVICES: '/administrate/devices',
    ADMINISTRATE_KITS: '/administrate/kits',
    ADMINISTRATE_CUSTOM_LOCATIONS: '/administrate/custom-locations',
    ADMINISTRATE_CUSTOM_LOCATIONS_CITIES: '/administrate/custom-locations/cities',
    ADMINISTRATE_CUSTOM_LOCATIONS_NEIGHBORHOODS: '/administrate/custom-locations/neighborhoods',
    ADMINISTRATE_REFERRING_FACILITIES: '/administrate/referring-facilities',
    ADMINISTRATE_REFERRING_FACILITIES_ACTIVE: '/administrate/referring-facilities/active',
    ADMINISTRATE_REFERRING_FACILITIES_ARCHIVED: '/administrate/referring-facilities/archived',
    ADMINISTRATE_SERVICE_AREAS: '/administrate/service-areas',
    ADMINISTRATE_CALL_ROUTING: '/administrate/callRouting',
    ADMINISTRATE_EDUCATIONAL_DOCUMENTS: '/administrate/patient-education-documents',
    FEATURE_SETTINGS: '/administrate/feature-settings',
    ADMINISTRATE_CLUSTER: (id = ':id') => `/administrate/clusters/${id}` as const,
    ADMINISTRATE_CLUSTERS: '/administrate/clusters',
} as const;

const ORDERS = {
    ORDERS: '/orders',
    SCHEDULE_ACTIVITIES: '/orders/schedule_activities',
    SCHEDULE_ACTIVITIES_VENDORS_LIST: '/orders/schedule_activities/vendors_list',
    SCHEDULE_ACTIVITIES_CREATE_APPOINTMENT: '/orders/schedule_activities/create_appointment',
    SCHEDULE_ACTIVITIES_CREATE_SCHEDULING_REQUEST: '/orders/schedule_activities/create_service_request',
    SCHEDULE_MULTIPLE_ACTIVITIES_ACROSS_MULTIPLE_DAYS: (orderId = ':orderId') =>
        `/orders/${orderId}/schedule_multiple_activities_across_multiple_days` as const,

    SERVICE_REQUEST_SCHEDULE_ACTIVITIES: (handOffId = ':handOffId') =>
        `/orders/service_request/${handOffId}/schedule_activities`,
    SERVICE_REQUEST_SCHEDULE_ACTIVITIES_VENDORS_LIST: (id = ':handOffId') =>
        `/orders/service_request/${id}/schedule_activities/vendors_list` as const,
    SERVICE_REQUEST_SCHEDULE_ACTIVITIES_CREATE_APPOINTMENT: (id = ':handOffId') =>
        `/orders/service_request/${id}/schedule_activities/create_appointment` as const,
    SERVICE_REQUEST_SCHEDULE_MULTIPLE_ACTIVITIES_ACROSS_MULTIPLE_DAYS: (handOffId = ':handOffId') =>
        `/orders/service_request/${handOffId}/schedule_multiple_activities_across_multiple_days`,
    SERVICE_REQUEST_PENDING_CANCELLATION: (id = ':id') => `/orders/service_request/${id}/pending_cancellation` as const,

    CREATE_IN_HOME_ACTIVITY_BUNDLE: (orderId = ':orderId') => `/orders/${orderId}/create-activity-bundle` as const,

    ACKNOWLEDGE: (orderId = ':orderId') => `/orders/${orderId}/acknowledge` as const,
    CANCEL_ACKNOWLEDGE: (orderId = ':orderId') => `/orders/${orderId}/cancel-acknowledge` as const,
    CHANGE_DATE_ACKNOWLEDGE: (orderId = ':orderId') => `/orders/${orderId}/change-date-acknowledge` as const,

    ADD_PRN_ACTIVITIES: (orderId = ':orderId') => `/orders/${orderId}/add-prn-activities` as const,
    MODIFICATION_SERVICE_REQUEST: (id = ':id') => `/orders/modification-service-request/${id}` as const,
} as const;

const APPOINTMENTS = {
    APPOINTMENTS: '/appointments',
    EDIT_IN_HOME_APPOINTMENT: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/edit` as const,
    EDIT_IN_HOME_APPOINTMENT_ADDITIONAL_ACTIVITIES: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/add_additional_activities` as const,
    EDIT_IN_HOME_APPOINTMENT_CHANGE_VENDOR: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/change_vendor` as const,
    CHECK_IN_IN_HOME_APPOINTMENT: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/check-in` as const,
    REVIEW_SELF_CHECK_IN_IN_HOME_APPOINTMENT: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/review-self-check-in` as const,
    REVIEW_SELF_CHECK_OUT_IN_HOME_APPOINTMENT: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/review-self-check-out` as const,
    CHECK_OUT_IN_HOME_APPOINTMENT: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/check-out` as const,
    CONFIRM_IN_HOME_APPOINTMENT: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/confirm` as const,
    DOCUMENTATION: (appointmentId = ':appointmentId') =>
        `/appointments/${appointmentId}/in-home/documentation` as const,
} as const;

const PATIENTS = {
    PATIENTS_LIST: '/patients',
    PATIENTS_CREATE: '/patients/create',
    PATIENTS_CENSUS: '/patients/census',
    PATIENTS_INACTIVE: '/patients/inactive',
    PATIENTS_ASSIGNMENT: '/patients/assignment',
    PATIENTS_VOIDED: '/patients/voided',
    PATIENTS_PENDING: '/patients/pending',
} as const;

const PATIENT_SINGLE = {
    PATIENT_SINGLE: (id = ':patientId') => `/patient/${id}` as const,
    PATIENT_VERIFY: (id = ':patientId') => `/patient/${id}/verify` as const,
    PATIENT_SINGLE_OVERVIEW: (id = ':patientId') => `/patient/${id}/overview` as const,
    PATIENT_SINGLE_PROFILE: (id = ':patientId') => `/patient/${id}/profile` as const,
    PATIENT_SINGLE_ALERTS: (id = ':patientId') => `/patient/${id}/alerts` as const,
    PATIENT_SINGLE_APPOINTMENTS: (id = ':patientId') => `/patient/${id}/appointments` as const,
    PATIENT_SINGLE_ORDERS: (id = ':patientId') => `/patient/${id}/orders` as const,
    PATIENT_SINGLE_ORDERS_NEW: (id = ':patientId') => `/patient/${id}/orders/new` as const,
    PATIENT_SINGLE_ORDERS_IN_HOME: (id = ':patientId', orderId?: string) =>
        `/patient/${id}/orders/in-home/${orderId || ''}` as const,
    PATIENT_SINGLE_ORDERS_GENERAL: (id = ':patientId') => `/patient/${id}/orders/general` as const,
    PATIENT_SINGLE_ORDERS_COMPLETED: (id = ':patientId') => `/patient/${id}/orders/completed` as const,
    PATIENT_SINGLE_ORDERS_PENDING_DOCUMENTATION: (id = ':patientId') =>
        `/patient/${id}/orders/pending-documentation` as const,
    PATIENT_SINGLE_ORDERS_MANUAL: (id = ':patientId') => `/patient/${id}/orders/manual` as const,
    PATIENT_SINGLE_ASSIGNMENTS: (id = ':patientId') => `/patient/${id}/assignments/` as const,
    PATIENT_BARCODES: (id = ':patientId') => `/patient/${id}/barcodes/` as const,
    PATIENT_SINGLE_ASSIGNMENTS_ASSIGNED_NURSE: (id = ':patientId') =>
        `/patient/${id}/assignments/assigned-nurse` as const,
    PATIENT_SINGLE_ASSIGNMENTS_SERVICE_COORDINATOR: (id = ':patientId') =>
        `/patient/${id}/assignments/service-coordinator` as const,
    PATIENT_SINGLE_ASSIGNMENTS_ATTENDING_PHYSICIAN: (id = ':patientId') =>
        `/patient/${id}/assignments/attending-physician` as const,
    PATIENT_SINGLE_ASSIGNMENTS_RESPONSIBLE_CLINICIAN: (id = ':patientId') =>
        `/patient/${id}/assignments/responsible-clinician` as const,
    PATIENT_SINGLE_METRICS: (id = ':patientId') => `/patient/${id}/metrics` as const,
    PATIENT_THRESHOLDS: (patientId = ':patientId', metricType = ':metricType') =>
        `/patient/${patientId}/metrics/${metricType}/thresholds` as const,
    PATIENT_SINGLE_SURVEYS: (id = ':patientId') => `/patient/${id}/surveys` as const,
    PATIENT_SINGLE_MEDIA: (id = ':patientId') => `/patient/${id}/media` as const,
    PATIENT_SINGLE_CLINICAL_DOCUMENTS: (id = ':patientId') => `/patient/${id}/clinical-documents` as const,
    PATIENT_SINGLE_EQUIPMENT: (id = ':patientId') => `/patient/${id}/equipment` as const,
    PATIENT_SINGLE_CALL_HISTORY: (id = ':patientId') => `/patient/${id}/call-history` as const,
    PATIENT_SINGLE_TASK_HISTORY: (id = ':patientId') => `/patient/${id}/task-history` as const,
} as const;

const GENERAL = {
    HOMEPAGE: '/',
    VENDOR_DICTIONARY: '/vendor-dictionary',
    MULTI_MULTI_NOT_SUPPORTED: '/multi-multi-not-supported',
} as const;

const SERVICE_AREA = {
    USER_ACCESS_LOG: '/administrate/userAccessLog',
    SERVICE_AREAS_PAGE: '/administrate/service-areas',
    GRAND_SERVICE_AREA: (id = ':id?') => `/administrate/service-areas/grand/${id}` as const,
    CREATE_SERVICE_AREA: '/administrate/service-areas/create',
    UPDATE_SERVICE_AREA: (id = ':id', type = ':type') => `/administrate/service-areas/${type}/${id}` as const,
} as const;

const EDUCATION_DOCUMENTS = {
    EDUCATION_DOCUMENTS: '/administrate/patient-education-documents',
    EDUCATION_DOCUMENT_ADD: '/administrate/patient-education-documents/create',
    EDUCATION_DOCUMENT_EDIT: (id = ':id') => `/administrate/patient-education-documents/${id}` as const,
    EDUCATION_DOCUMENT_HISTORY: (id = ':id') => `/administrate/patient-education-documents/history/${id}` as const,
} as const;

const PATIENTS_APPOINTMENTS_CALENDAR = {
    MULTI_PATIENT_APPOINTMENTS_CALENDAR: '/calendar/multi-patient',
    PROVIDER_VIEW_APPOINTMENTS_CALENDAR: (query?: string) =>
        `/calendar/provider-view${query ? `/${query}` : ''}` as const,
} as const;

const ACTION_QUEUE = {
    ACTION_QUEUE: '/action-queue',
    ACTION_QUEUE_SCHEDULING: '/action-queue/scheduling',
    ACTION_QUEUE_MODIFICATIONS: '/action-queue/modifications',
    ACTION_QUEUE_VENDOR_CHANGES: '/action-queue/vendor-changes',
    ACTION_QUEUE_CANCELLATIONS: '/action-queue/cancellations',
    ACTION_QUEUE_DOCUMENTATION: '/action-queue/documentation',
    CHANGE_VENDOR_REQUEST: (id = ':id') => `/action-queue/change-vendor-request/${id}` as const,
} as const;

const MY_PAGE = {
    MY_PAGE: (nurseId = ':nurseId') => `/my-page/${nurseId}` as const,
    MY_PAGE_PATIENTS: (nurseId = ':nurseId') => `${MY_PAGE.MY_PAGE(nurseId)}/my-patients` as const,
    MY_PAGE_AGENDA: (nurseId = ':nurseId') => `${MY_PAGE.MY_PAGE(nurseId)}/agenda` as const,
} as const;

const VENDOR_DICTIONARY = {
    VENDOR_DICTIONARY_PAGE: (params = ':vendorId?/:siteId?/:actorId?/:activityId?') =>
        `/vendor-dictionary/${params}` as const,
} as const;

const PERFORMANCE_IMPROVEMENT_LOG = {
    PERFORMANCE_IMPROVEMENT_LOG: '/performance-improvement-log',
    PERFORMANCE_IMPROVEMENT_LOG_NEW: '/performance-improvement-log/new',
    PERFORMANCE_IMPROVEMENT_LOG_IN_REVIEW: '/performance-improvement-log/in-review',
    PERFORMANCE_IMPROVEMENT_LOG_CLOSED: '/performance-improvement-log/closed',
    CREATE_LOG_ENTRY: '/performance-improvement-log/create',
    REVIEW_LOG_ENTRY: (id = ':id') => `/performance-improvement-log/review/${id}` as const,
    EDIT_LOG_ENTRY: (id = ':id') => `/performance-improvement-log/edit/${id}` as const,
    REOPEN_LOG_ENTRY: (id = ':id') => `/performance-improvement-log/reopen/${id}` as const,
} as const;

const VIDEO_CALL = {
    VIDEO_SCHEDULED_CALL_PAGE: (id = ':invitationToken') => `/video-scheduled-call/${id}`,
} as const;

const CARE_PLANNING = {
    CARE_PLANNING: (patientId = ':patientId') => `/care-planning/${patientId}` as const,
    CARE_PLANNING_FIRST_STEP: (patientId = ':patientId') => `/care-planning/${patientId}/first-step` as const,
    CARE_PLANNING_SECOND_STEP: (patientId = ':patientId') => `/care-planning/${patientId}/second-step` as const,
} as const;

const CALL_BELL_LOG = {
    CALL_BELL_LOG: '/call-bell-log',
    CALL_BELL_LOG_VIDEO: '/call-bell-log/video',
} as const;

export default {
    ...ADMINISTRATE,
    ...PATIENTS,
    ...PATIENT_SINGLE,
    ...ORDERS,
    ...GENERAL,
    ...SERVICE_AREA,
    ...EDUCATION_DOCUMENTS,
    ...APPOINTMENTS,
    ...PATIENTS_APPOINTMENTS_CALENDAR,
    ...ACTION_QUEUE,
    ...MY_PAGE,
    ...VENDOR_DICTIONARY,
    ...PERFORMANCE_IMPROVEMENT_LOG,
    ...VIDEO_CALL,
    ...CARE_PLANNING,
    ...CALL_BELL_LOG,
};
